import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { InView } from "react-intersection-observer";
import cls from "classnames";
import { Transition } from "@headlessui/react";

import * as Track from "@qoohoo/common/utils/analytics/track";

import "swiper/css";
import "swiper/css/pagination";

import Modal from "@qoohoo/common/library/modals/Modal";
import { button, pageview } from "@qoohoo/common/constants/analytics";

const TestimonialsSection = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [animateHeader, setAnimateHeader] = useState(false);

  const users = [
    {
      name: "Vinay Rathore",
      type: "Music Teacher",
      avatar_url: "/assets/images/testimonial-vinay.webp",
      testimonial:
        "qoohoo is simply a one-stop solution to many of my problems. Organising and maintaining online courses at Musicwale was a mess until qoohoo came. As a creator I think the most powerful thing is knowing that it's “you” who has the power.",
    },
    {
      name: "Raghavendra",
      type: "Finance Expert",
      avatar_url: "/assets/images/testimonial-ragh.webp",
      testimonial:
        "qoohoo enabled us to monetize our existing community by removing all major roadblocks like payment gateway, course & community offerings. We were able to build our first 300 paid subscriptions on qoohoo in a span of just 2 months.",
    },
    {
      name: "Rachit",
      type: "Music and Literature Enthusiast",
      avatar_url: "/assets/images/testimonial-rachit.webp",
      testimonial:
        "Bookclub won't even exist if it weren't for qoohoo. It is so easy to create, maintain and monetise a community on qoohoo that I don't think twice before investing my time there. And now I am building a side venture apart from my music..",
    },
    {
      name: "Muskan Bamne",
      type: "TV Actress",
      avatar_url: "/assets/images/testimonial-muskan.webp",
      testimonial:
        "qoohoo filled the communication gap that I always felt with my audience on social media. It's smooth and fun app to connect with people directly and share things without privacy issues.",
    },
    // {
    //   name: "Aparna",
    //   type: "Mandala Artist",
    //   avatar_url: "/assets/images/testimonial-aparna.png",
    //   testimonial:
    //     "Mandala art was always a stress buster to me, never thought it could bring the creator out of me. It is qoohoo that gave me the vision, possibility, and platform to monetize my craft.",
    // },
    // {
    //   name: "Hricha Debraj",
    //   type: "SaReGaMaPa Winner 2002",
    //   avatar_url: "/assets/images/testimonial-hricha.png",
    //   testimonial:
    //     "Having a great experience in engaging with the musical enthusiasts during my workshop. The process for conducting workshop is seamless and easy on qoohoo.",
    // },
  ];

  return (
    <section className="px-5 pt-24 md:py-20 lg:grid sm:grid-flow-row sm:grid-cols-12 sm:gap-x-5 sm:px-20 lg:px-[4.25rem] xl:px-20 max-w-[1536px] 2xl:mx-auto">
      <InView
        onChange={(inView) => {
          if (inView) {
            setAnimateHeader(true);
          }
        }}
        rootMargin="-60% 0% -40% 0%"
        className={cls(
          "lg:grid sm:grid-flow-row sm:grid-cols-12 sm:gap-x-5 col-span-full transition-all header-reveal-animation",
          { "translate-y-8 opacity-0": !animateHeader },
          {
            "translate-y-0 opacity-100 block": animateHeader,
          }
        )}
      >
        <div className="col-span-full flex justify-center items-center flex-col">
          <h2 className="text-2xl font-bold text-center sm:text-3xl md:text-4xl lg:text-5xl font-inter">
            Loved by 1000+ creators
          </h2>
          <p className="text-sm sm:text-xl sm:leading-9 text-[#DBDBDB] mt-4 lg:mt-6 text-center mb-14 md:mb-[72px] font-nunito max-w-none sm:max-w-[80%] lg:max-w-[464px]">
            Hear what our creators across various categories say about qoohoo 😍
          </p>
        </div>
      </InView>
      <div className="flex justify-center w-full pt-10 lg:col-start-1 lg:col-end-6">
        <div className="relative w-[286px] h-[374px] md:min-w-[413px] md:min-h-[540px] transition-transform hover:scale-105 hover:cursor-pointer">
          <img
            src="/assets/images/doodle-strings.svg"
            alt="Some doodle graphics"
            className="absolute select-none -top-16 -right-7"
          />
          <button
            className="overflow-hidden relative h-full w-full rotate-[-5.4deg] bg-[#1B1F22] rounded-2xl"
            onClick={() => {
              Track.buttonClick(button.testimonial_video, {
                current_screen: pageview.home,
              });
              setVideoModalOpen(true);
            }}
          >
            <img
              src="/assets/images/testimonial-video-thumbnail.webp"
              alt="Testimonial video thumbnail image"
              className="object-cover object-center w-full h-full select-none"
            />
            <div className="absolute bottom-0 left-0 w-full h-28 bg-gradient-to-t from-black/75 to-black/5"></div>
            <div className="absolute flex items-center space-x-3 bottom-4 left-4">
              <img
                src="/assets/icons/play-testimonial.svg"
                alt="Play video button icon select-none"
              />
              <div className="text-sm">
                <div className="font-semibold">Aparna Mishra</div>
                <div className="mt-0.5">Mandala Artist</div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="hidden lg:block lg:col-start-7 lg:col-end-12 px-2.5 sm:px-0 font-inter space-y-12 pt-6">
        {users.map((user, id) => {
          return (
            <div key={id} className="max-w-[460px]">
              <div className="flex items-center justify-start w-full mb-6 space-x-4">
                <div className="rounded-full w-14 h-14">
                  <img
                    src={user.avatar_url}
                    alt={user.name}
                    className="w-full h-full select-none"
                  />
                </div>
                <div className="text-sm">
                  <div className="font-semibold">{user.name}</div>
                  <div className="mt-1.5 text-[#CCCCCC]">{user.type}</div>
                </div>
              </div>
              <p className="font-nunito">{user.testimonial}</p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center mt-16 lg:block">
        <div className="pb-10 overflow-hidden max-w-[400px]">
          <Swiper
            modules={[Pagination]}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return '<span class="!bg-white ' + className + '"></span>';
              },
            }}
            className="!overflow-visible px-2.5 lg:hidden testimonial-slider"
            spaceBetween={50}
          >
            {users.map((user, id) => {
              return (
                <SwiperSlide key={id} style={{ cursor: "grab" }}>
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-12 h-12 rounded-full">
                      <img
                        src={user.avatar_url}
                        alt={user.name}
                        className="w-full h-full select-none"
                      />
                    </div>
                    <div className="mt-3 text-xs text-center">
                      <div className="font-semibold">{user.name}</div>
                      <div className="mt-1 text-[#CCCCCC]">{user.type}</div>
                    </div>
                  </div>
                  <p className="text-center text-sm px-2.5 mt-4 mb-10">
                    {user.testimonial}
                  </p>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      <Modal
        isOpen={videoModalOpen}
        closeModal={() => {
          setVideoModalOpen(false);
        }}
      >
        <Transition.Child
          enter="duration-300"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="duration-300"
          leaveFrom="opactity-100 scale-100"
          leaveTo="opacity-0 scale-90"
          className="h-[600px] fixed z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
          <iframe
            className="h-[600px] overflow-hidden rounded-2xl"
            src="https://www.youtube.com/embed/UR1FuWCf5to"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Transition.Child>
      </Modal>
    </section>
  );
};

export default TestimonialsSection;
