import { useCallback } from "react";
import Link from "next/link";
import cls from "classnames";
import * as Track from "@qoohoo/common/utils/analytics/track";
import { button, link, pageview } from "@qoohoo/common/constants/analytics";
import { BecomeACreatorButton } from "./HeroSection";

const BottomCTASection = ({
  downloadLink,
  showAndroidButton,
  showIOSButton,
}) => {

  const actionButtons = (
    <>
      {/* Become a creator button */}
      <BecomeACreatorButton className="max-w-[20rem] mx-auto sm:ml-0 sm:mr-4" />

      {/* Download button */}
      <Link
        href={downloadLink}
        className={cls(
          "flex justify-center mx-auto sm:mx-0 font-nunito sm:text-xl font-bold text-[#181B1E] py-4 sm:py-[18px] px-8 sm:px-4",
          "mb-4 bg-white w-full max-w-[20rem] sm:max-w-[220px]",
          "rounded-full hover:scale-105 duration-200 transition-all get-started-button button-solid-shadow"
        )}
        onClick={useCallback(() => {
          Track.buttonClick(button.download, {
            url: downloadLink,
            current_screen: pageview.home,
          });
        })}
      >
        <span>Download Now</span>
      </Link>
    </>
  );


  return (
    <section className="pt-10 sm:pt-24 lg:pt-20 font-nunito">
      <img
        src="/assets/images/wave.svg"
        alt="Wave illustration seperating FAQ and CTA sections"
        className="hidden w-full select-none md:block"
      />
      <img
        src="/assets/images/wave-mobile.svg"
        alt="Wave illustration seperating FAQ and CTA sections"
        className="w-full select-none md:hidden"
      />
      <div className="relative bg-[#121516] pt-20 md:pb-[220px] md:grid grid-flow-row md:grid-flow-col grid-cols-4 md:grid-cols-12 px-5 sm:px-8 md:px-12 lg:px-20 gap-5 pb-[60px]">
        <div className="relative md:static flex flex-col items-center col-span-4 md:col-span-5 lg:ml-10 lg:mr-[18px] text-center">
          <div className="relative rounded-full">
            <img
              src="/assets/images/doodle-rocket.svg"
              alt="Doodle arrow poiting to Get Started Button"
              className="absolute md:hidden top-[-116px] sm:top-[-132px] right-[-104px] sm:right-[-144px] select-none"
            />
            <img
              src="/assets/images/creator-cta.svg"
              alt="Chhaya Saxena's Image"
              className="w-16 h-16 md:w-[88px] md:h-[88px] rounded-full select-none"
            />
          </div>
          <div className="text-sm text-[#DCDCDC] mt-4 flex flex-col">
            <div className="font-bold">Chhaya Saxena</div>
            <span className="mt-1">Creator in Yoga</span>
          </div>
          <p className="px-5 mt-5 !leading-[1.5] md:px-0 max-w-[400px]">
            qoohoo is a blessing for content creators like me as managing my
            students, their payments and talking to them is now really easy. I
            can finally take out some time to think of my content and Yoga.
          </p>
        </div>
        <div className="relative flex flex-col items-center justify-center mt-[71px] md:items-start col-span-full md:col-start-8 md:col-end-13 md:mt-0">
          <div className="relative">
            <h2 className="text-2xl font-bold sm:text-3xl md:text-4xl lg:text-5xl font-inter">
              Let's get started..
            </h2>
            <img
              src="/assets/images/doodle-rocket.svg"
              alt="Doodle arrow poiting to Get Started Button"
              className="absolute top-[-100px] hidden right-[32px] md:block select-none"
            />
            <img
              src="/assets/images/doodle-arrow-cta-mobile.svg"
              alt="Doodle arrow poiting to Get Started Button"
              className="absolute left-[-76px] top-[-70px] md:hidden select-none"
            />
          </div>
          <p className="mt-4 md:mt-6 text-[#CCCCCC] text-sm md:text-xl max-w-[260px] md:max-w-[360px] text-center md:text-left">
            Walk a successful creator journey with qoohoo. Like a pro! ✌
          </p>

          <div className="flex flex-wrap justify-center md:justify-start w-full mt-16 sm:gap-2 xl:gap-0 gap-4">
            {actionButtons}
          </div>

          <img
            src="/assets/images/doodle-arrow-cta.svg"
            alt="Doodle arrow poiting to Get Started Button"
            className="absolute hidden bottom-[-144px] left-[-180px] lg:left-[-236px] md:block select-none"
          />
        </div>
      </div>
    </section>
  );
};

export default BottomCTASection;
