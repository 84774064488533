import { useState, useRef, useEffect, useCallback } from "react";
import Link from "next/link";
import * as Track from "@qoohoo/common/utils/analytics/track";
import cls from "classnames";
import { creatorOnboardingUrl } from "constants/constants";

import { button, link, pageview } from "@qoohoo/common/constants/analytics";
import useScreenSize from "@qoohoo/common/hooks/useScreenSize";

export function BecomeACreatorButton({className}) {
  function handleBecomeACreatorClick() {
    Track.buttonClick(button.become_creator, {
      current_screen: pageview.home,
    });
  }

  return (
   <>
     <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
     <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
     <a
       className={cls(
        className,
        "flex bg-[#384af1] text-white sm:text-xl font-bold cursor-pointer",
        "justify-center w-full sm:max-w-[220px]",
        "mb-4 py-4 sm:py-[18px] px-8 sm:px-4 rounded-full hover:scale-105 duration-200 transition-all" +
        " get-started-button button-solid-shadow-blue"
      )}
       alt="become-a-creator"
       href={creatorOnboardingUrl}
       onClick={handleBecomeACreatorClick}
       target="_blank"
       rel="noopener"
     >
       Become a creator
     </a>
   </>
  );
}

const HeroSection = ({ downloadLink, showAndroidButton, showIOSButton }) => {
  const [lottie, setLottie] = useState(null);
  const lottieContainerMobile = useRef(null);
  const lottieContainerDesktop = useRef(null);

  useEffect(() => {
    import("lottie-web").then((Lottie) => setLottie(Lottie.default));
  }, []);

  const playLottie = useCallback((lottieContainer) => {
    lottie && lottie.loadAnimation({
      container: lottieContainer.current,
      path: "/assets/lottie-animations/hero-landing.json",
      autoplay: true,
      loop: true,
      renderer: "svg",
    });
  }, [lottie]);

  useEffect(() => {
    if (lottie) {
      lottieContainerMobile.current && playLottie(lottieContainerMobile);
      lottieContainerDesktop.current && playLottie(lottieContainerDesktop);
    }

    return () => lottie && lottie.destroy();
  }, [lottie, playLottie]);

  const actionButtons = (
    <>
      {/* Become a creator button */}
      <BecomeACreatorButton className="sm:mr-4 max-w-[20rem]" />

      {/* Download button */}
      <Link
        href={downloadLink}
        className={cls(
          "flex mb-4 font-nunito sm:text-xl font-bold text-[#181B1E] py-4 sm:py-[18px] px-8 sm:px-4",
          "bg-white",
          "justify-center w-full max-w-[20rem] sm:max-w-[220px]",
          "rounded-full hover:scale-105 duration-200 transition-all get-started-button button-solid-shadow"
        )}
        onClick={useCallback(() => {
          Track.buttonClick(button.download, {
            url: downloadLink,
            current_screen: pageview.home,
          });
        })}
      >
        <span>Download Now</span>
      </Link>
    </>
  );

  return (
    <section className="relative">
      <div className="flex px-5 sm:px-8 md:px-12 lg:px-[4.25rem] xl:px-20 z-1 mb-12 md:mb-20 max-w-[1536px] 2xl:mx-auto relative">
        <div className="z-0 w-full flex flex-col sm:block sm:w-3/4 md:w-[60%]">
          <h1 className="mt-[69px] sm:mt-20 sm:text-left text-[40px] md:text-5xl lg:text-[56px] !leading-[1.4] max-w-[300px] sm:max-w-[460px] md:max-w-[560px] tracking-[-0.25] font-bold font-inter">
            Monetise your audience across{" "}
            <div className="text-slider">
              <ul className="text-slider-words">
                <li className="text-[#2AABEE]">Telegram</li>
                <li className="text-[#25D366]">WhatsApp</li>
                <li className="text-[#EA3322]">YouTube</li>
                <li className="gradient-instagram">Instagram</li>
                <li className="text-[#1877F2]">Facebook</li>
                <li className="text-[#ECB22E]">Slack</li>
                <li className="text-[#6975FE]">Discord</li>
                <li className="text-[#2AABEE]">Telegram</li>
              </ul>
            </div>
          </h1>
          <p className="sm:text-left sm:text-2xl !leading-[1.5] text-[#DBDBDB] mt-6 sm:mt-9 mb-12 sm:px-0 max-w-[260px] sm:max-w-[460px] md:max-w-[520px] font-nunito">
            Launch your paid subscriptions, events, courses, payment pages and more for your audience
          </p>
          <div className="flex flex-wrap gap-4 sm:gap-0">
            {actionButtons}
          </div>
        </div>

        <div className="max-w-[480px] absolute object-contain top-1/2 -translate-y-1/2 hidden md:block md:h-3/5 md:right-10 lg:h-3/4 lg:right-20 xl:h-full xl:mt-4 xl:right-40 z-[-2] select-none">
          <span ref={lottieContainerDesktop}></span>
        </div>
      </div>

      <div className="object-contain md:hidden select-none mx-auto w-4/5 max-w-md px-5 sm:px-8 mb-20">
        <span ref={lottieContainerMobile}></span>
      </div>

      <img
        src="/assets/images/star-1.svg"
        alt="Illustration of 2 creators"
        className="absolute top-5 left-5 h-6 w-6 sm:h-auto sm:w-auto sm:top-7 sm:left-[48px] z-[-1] select-none"
      />
      <img
        src="/assets/images/star-2.svg"
        alt="Illustration of 2 creators"
        className="absolute top-16 right-3 w-5 h-5 sm:h-auto sm:w-auto sm:top-14 sm:right-[120px] lg:right-[200px] z-[-1] select-none"
      />
      <img
        src="/assets/images/star-3.svg"
        alt="Illustration of 2 creators"
        className="absolute top-[200px] right-[69px] w-5 h-5 sm:h-auto sm:w-auto sm:top-[120px] sm:right-[300px] lg:right-[456px] max-w-6 max-h-6 z-[-1] select-none"
      />
    </section>
  );
};

export default HeroSection;
