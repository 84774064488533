import { useState } from "react";
import cls from "classnames";
import { InView } from "react-intersection-observer";

const FeaturesSection = () => {
  const [animateHeader, setAnimateHeader] = useState(false);

  const features = [
    {
      icon: "/assets/icons/feature-pages.svg",
      title: "Beautiful pages",
      description:
        "Look social and yet elegant, along with testimonials that praise your work",
    },
    {
      icon: "/assets/icons/feature-insights.svg",
      title: "Growth tools",
      description:
        "Design, Marketing & Conversion of your leads - all at one place",
    },
    {
      icon: "/assets/icons/feature-discount.svg",
      title: "Discounts & Promos",
      description:
        "Grow your audience or retain members with easily shareable discounts",
    },
    {
      icon: "/assets/icons/feature-privacy.svg",
      title: "Privacy",
      description:
        "End-to-end encryption and screen recording protection of your content",
    },
    {
      icon: "/assets/icons/feature-analytics.svg",
      title: "Analytics",
      description: "Understand your members better with in-built analytics",
    },
    {
      icon: "/assets/icons/feature-multiple-subscriptions.svg",
      title: "Multiple subscriptions",
      description:
        "Run multiple options of payments & subscriptions on a single link",
    },
  ];

  return (
    <section className="flex w-full px-5 sm:px-8 md:px-12 lg:px-[4.25rem] xl:px-20 pt-32 pb-[100px] md:py-20 font-inter mx-auto max-w-[1536px]">
      <div className="grid w-full grid-flow-col grid-cols-4 gap-5 sm:grid-cols-12">
        <div className="relative bg-[#22262A] col-span-full lg:col-start-1 lg:col-end-13 py-10 px-4 sm:px-8 md:px-12 sm:py-16 rounded-[2rem]">
          <img
            src="/assets/images/sitting-illustration.svg"
            alt="Sitting person illustration"
            className="absolute top-[-106px] sm:top-[-112px] -left-2 sm:left-3 scale-[0.66] sm:scale-100 select-none"
          />
          <div className="w-full flex flex-col items-center">
            <InView
              onChange={(inView) => {
                if (inView) {
                  setAnimateHeader(true);
                }
              }}
              rootMargin="-60% 0% -40% 0%"
              className={cls(
                "transition-all header-reveal-animation",
                { "translate-y-8 opacity-0": !animateHeader },
                {
                  "translate-y-0 opacity-100 block": animateHeader,
                }
              )}
            >
              <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold !leading-normal tracking-normal text-center">
                And there is more..
              </h2>
              <p className="text-sm sm:text-base md:text-lg text-[#DBDBDB] mt-2 md:mt-3 lg:mt-4 sm:mt-6 font-nunito text-center">
                for all kinds of creators and professionals 😎
              </p>
            </InView>
            <div className="w-full space-y-14 md:mt-16 mt-14 lg:mt-12 sm:grid sm:grid-flow-row sm:row-start-auto sm:grid-cols-2 sm:gap-x-5 sm:space-y-0 sm:gap-y-14 md:gap-y-12 md:gap-x-16 xl:grid-cols-3 xl:gap-y-12 xl:gap-x-16 lg:max-w-[972px]">
              {features.map((feature, id) => {
                return (
                  <div key={id} className="sm:col-span-1">
                    <div className="bg-[#16191B] h-16 w-16 rounded-2xl flex items-center justify-center">
                      <img
                        src={feature.icon}
                        alt={feature.title}
                        className="select-none"
                      />
                    </div>
                    <h3 className="mt-5 text-lg font-semibold sm:mt-6 sm:text-xl">
                      {feature.title}
                    </h3>
                    <p className="mt-3 text-sm sm:text-base font-nunito max-w-[280px] lg:max-w-none">
                      {feature.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
