import cls from "classnames";
import { useRef, useEffect, forwardRef } from "react";

function getPositionClass(align) {
  let className = 'left-1/2 top-1/2 text-center watermark';

  if(align === WaterMarkPositions.bottomLeft) {
    className = 'bottom-4 left-4 sm:bottom-6 sm:left-6';
  } else if(align === WaterMarkPositions.center) {
    className = 'left-1/2 top-1/2 text-center watermark';
  }

  return className;
}

const WaterMark = forwardRef(
  (
    {
      text,
      opacity = 0.15,
      color = "white",
      strokeColor = "black",
      fontSize = "16px",
      align = WaterMarkPositions.center,
      isAnimated = false,
    },
    ref
  ) => {
    return (
      <pre
        className={cls(
          "absolute z-[1000]  sm:text-md md:text-lg lg:text-xl pointer-events-none",
          isAnimated && "watermark-animation",
          getPositionClass(align)
        )}
        style={{
          opacity: opacity,
          fontSize,
          color,
          fontFamily: 'inherit',
          textShadow: `1px 1px 0 ${strokeColor}`
        }}
        ref={ref}
      >
        {text}
      </pre>
    );
  }
);

export const WaterMarkPositions = {
  bottomLeft: 'bottomLeft',
  center: 'center',
}

export default WaterMark;
