import cls from "classnames";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronRightIcon } from "@qoohoo/common/library/Icons";
import NoSSR from "@qoohoo/common/components/NoSSR";

const ArrowNext = ({ selectedIndex, onItemChange, numItems }) => {
  const onClick = () => {
    onItemChange((selectedIndex+1)%numItems);
  }  
  return (
      <div className="absolute -right-6 top-1/2" onClick={onClick}>
        <ChevronRightIcon className="text-white h-7 w-7 stroke-current -translate-y-1/2" />
      </div>
    );
  }

const ArrowPrev = ({ selectedIndex, onItemChange, numItems }) => {
  const onClick = () => {
    let index = selectedIndex - 1;
    
    if(index == -1) {
      index = numItems - 1;
    }

    onItemChange(index);
  }

  return (
  <div className="absolute -left-6 top-1/2" onClick={onClick}>
    <ChevronRightIcon className="text-white h-7 w-7 stroke-current -translate-y-1/2 origin-center rotate-180" />
  </div>
  );
}

const CarouselMobile = ({
  changeInterval,
  onToolChange,
  selectedIndex,
  Tools,
}) => {
  return (
    <div className="lg:hidden flex flex-col py-8 mt-14 w-full col-span-full bg-[#1D2225] bg-opacity-40 relative text-white font-nunito overflow-hidden">
      <div className="mx-auto max-w-[300px] w-full relative">
        <Carousel
          axis="horizontal"
          centerMode={true}
          centerSlidePercentage={100}
          emulateTouch={true}
          infiniteLoop={true}
          showArrows={true}
          showIndicators={false}
          showStatus={false}
          swipeable={true}
          interval={changeInterval}
          onChange={onToolChange}
          selectedItem={selectedIndex}
          showThumbs={false}
          renderArrowNext={() => (
            <ArrowNext
              selectedIndex={selectedIndex}
              onItemChange={onToolChange}
              numItems={Tools.length}
            />
          )}
          renderArrowPrev={() => (
            <ArrowPrev
              selectedIndex={selectedIndex}
              onItemChange={onToolChange}
              numItems={Tools.length}
            />
          )}
          className="tools-carousel"
        >
          {Tools.map((Tool, index) => {
            const isSelected = index === selectedIndex;

            return (
              <div
                key={index}
                className={cls(
                  "flex items-center justify-center",
                  isSelected ? "opacity-100" : "opacity-20"
                )}
              >
                <Tool.icon
                  className="object-contain h-10 w-10 mr-1 sm:mr-3 flex-shrink-0"
                  style={{
                    color: isSelected ? Tool.highlightColor[0] : "white",
                  }}
                  fillPrimary={isSelected && Tool.highlightColor[0]}
                  fillSecondary={isSelected && Tool.highlightColor?.[1]}
                />
                <div className="text-2xl whitespace-nowrap">
                  {Tool.label}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
      <NoSSR>
      <div className="flex justify-center items-center w-full mt-3 sm:mt-4">
        <Transition
          key={selectedIndex}
          appear={true}
          show={true}
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="transform opacity-0 scale-[0.98]"
          enterTo="transform opacity-100 scale-[1]"
        >
          <div className="w-full max-w-[480px] aspect-w-1 aspect-h-1 mt-4">
            <img
              src={Tools[selectedIndex].image}
              className="object-contain h-full w-full"
            />
          </div>
        </Transition>
      </div>
      </NoSSR>
    </div>
  );
};

export default CarouselMobile;
