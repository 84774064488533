import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  CoursesIconRect,
  PremiumContentIconRect,
  QoohooIconRect,
  SlotBookingIconRect,
  TelegramIconRect,
  WhatsappIconRect,
} from "@qoohoo/common/library/Icons";

import useScreenSize from "@qoohoo/common/hooks/useScreenSize";
import CarouselDesktop from "./CarouselDesktop";
import CarouselMobile from "./CarouselMobile";

const Tools = [
  {
    label: "Channels",
    icon: QoohooIconRect,
    image: "/assets/images/Illustration-channel.png",
    highlightColor: ["#FBE94B"],
  },
  {
    label: "Telegram Payments",
    icon: TelegramIconRect,
    image: "/assets/images/Illustration-telegram-payments.png",
    highlightColor: ["#2AABEE"],
  },
  {
    label: "Whatsapp Payments",
    icon: WhatsappIconRect,
    image: "/assets/images/Illustration-whatsapp-payments.webp",
    highlightColor: ["#25D366"],
  },
  {
    label: "Premium Content",
    icon: PremiumContentIconRect,
    image: "/assets/images/Illustration-premium-content.webp",
    highlightColor: ["#3DDB8F"],
  },
  {
    label: "Slot Booking",
    icon: SlotBookingIconRect,
    image: "/assets/images/Illustration-slot-booking.webp",
    highlightColor: ["#FF7A41"],
  },
  {
    label: "Courses",
    icon: CoursesIconRect,
    image: "/assets/images/Illustration-courses.png",
    highlightColor: ["#588AEE", "#2B6CED"],
  },
];

const changeInterval = 3000;

const ToolsSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const velocity = 100;
  const timeToNextIndex = useRef(velocity);

  const screenSize = useScreenSize();

  const isMobile = useMemo(() => {
    return ["xs", "sm", "md"].indexOf(screenSize) > -1;
  }, [screenSize]);

  let timeout = useRef(null);

  const onToolChange = useCallback(
    (newIndex, isUserAction=true) => {
      clearTimeout(timeout.current);

      const diff = Math.abs(selectedIndex - newIndex);
      timeToNextIndex.current = (velocity * diff) / 1000;

      setSelectedIndex(newIndex);
      
      if(!isUserAction) {
        timeout.current = setTimeout(
          () => onToolChange((newIndex + 1) % Tools.length, isUserAction),
          changeInterval
        );
      }
    },
    [selectedIndex]
  );

  useEffect(() => {
    timeout.current = setTimeout(() => onToolChange(1, false), changeInterval);

    () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <>
      {/* Start Loading Images as soon as component mounts */}
      {Tools.map(({ image }) => (
        <img key={image} src={image} className="hidden w-0 h-0 absolute" />
      ))}
      <section className="flex flex-col pt-16 -mt-16 pb-16 -mb-16 lg:mt-0 lg:mb-0 lg:pt-20 relative lg:grid lg:grid-flow-col lg:grid-cols-12 sm:py-20 lg:gap-5 px-0 lg:px-[4.25rem] xl:px-20 font-nunito items-center max-w-[1536px] 2xl:mx-auto">
        <div className="absolute inset-0 z-[-1]">
          <div className="w-full h-[15%] lg:h-1/5 bg-dot-pattern">
            <div className="h-full w-full bg-gradient-to-t from-transparent to-[#16191b80] lg:to-[#16191B]"></div>
          </div>
          <div className="w-full h-[70%] lg:h-3/5 bg-dot-pattern"></div>
          <div className="w-full h-[15%] lg:h-1/5 bg-dot-pattern">
            <div className="h-full w-full bg-gradient-to-b from-transparent to-[#16191B80] lg:to-[#16191B]"></div>
          </div>
        </div>
        <h1 className="text-white font-nunito text-2xl sm:text-5xl sm:leading-tight font-bold text-center w-full col-span-full">
          Magical tools to help you grow
        </h1>
        {isMobile ? (
          <CarouselMobile
            changeInterval={changeInterval}
            Tools={Tools}
            onToolChange={onToolChange}
            selectedIndex={selectedIndex}
          />
        ) : (
          <CarouselDesktop
            changeInterval={changeInterval}
            Tools={Tools}
            onToolChange={onToolChange}
            selectedIndex={selectedIndex}
            timeToNextIndex={timeToNextIndex.current}
          />
        )}
      </section>
    </>
  );
};

export default ToolsSection;
