import Link from "next/link";
import { useCallback } from "react";
import cls from "classnames";

import * as Track from "@qoohoo/common/utils/analytics/track";
import { button, link } from "@qoohoo/common/constants/analytics";
import useStore from "../store/store";
import {creatorOnboardingUrl} from "../constants/constants";

const featuredPages = [
  {
    label: "Telegram payments",
    trackLabel: link.telegram_payments_product_page,
    url: "/telegram-payments",
  },
  {
    label: "WhatsApp payments",
    trackLabel: link.whatsapp_payments_product_page,
    url: "/whatsapp-payments",
  },
  {
    label: "Monetise YouTube",
    trackLabel: link.monetise_youtube_videos_product_page,
    url: "/monetise-youtube-videos",
  },
];

const IndexFooter = ({ isLightMode, footerBackground }) => {
  const setIsGetStartedModalOpened = useStore(
    (state) => state.setIsGetStartedModalOpened
  );
  const current_screen = "footer";

  return (
    <footer
      className={cls(
        "px-5 pt-10 xl:pt-[60px] sm:px-8 md:px-12 lg:px-20 font-nunito",
        isLightMode ? "text-[#45494B]" : "bg-[#121516]"
      )}
      style={{ backgroundColor: isLightMode ? footerBackground : null }}
    >
      <div className="sm:grid grid-cols-1 sm:grid-cols-2 rows-auto md:grid-cols-4 gap-5 pb-[60px] sm:text-lg md:grid-flow-col xl:grid-cols-12 gap-y-16">
        <div className="row-start-1 lg:row-auto sm:col-span-full xl:col-span-3">
          <Link
            href="/"
            onClick={useCallback(() =>
              Track.linkClick(link.qoohoo_logo, { url: "/", current_screen })
            )}
          >
            <img
              src={
                isLightMode
                  ? "/assets/images/qoohoo-logo-black.svg"
                  : "/assets/images/qoohoo-logo-white.svg"
              }
              alt="qoohoo Logo"
              className="select-none"
            />
          </Link>

          <span
            className={cls(
              "text-xs sm:text-base",
              isLightMode ? "text-[#5A5A5A]" : "text-[#B8B8B8]"
            )}
          >
            Made with
            <img
              src="/assets/icons/heart.svg"
              alt="love"
              className="mx-1.5 inline select-none"
            />
            in India
          </span>
        </div>
        <div className="col-span-1 mt-8 sm:col-start-1 sm:row-start-2 xl:row-start-1 xl:col-span-2 sm:mt-0">
          <span className="font-bold">Company</span>
          <ul
            className={cls(
              "mt-5 space-y-3",
              isLightMode
                ? "text-[#5A5A5A] hover:children:text-black"
                : "text-[#B8B8B8] hover:children:text-white"
            )}
          >
            <li>
              <Link
                href="/pages/about"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.about, {
                    url: "/about",
                    current_screen,
                  })
                )}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                href="/blog"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.blog, {
                    url: "/blog",
                    current_screen,
                  })
                )}
              >
                Blog
              </Link>
            </li>

            <li>
              <a
                href={creatorOnboardingUrl}
                target="_blank"
                rel="noopener"
                className={cls(
                  "cursor-pointer",
                )}
                onClick={() => {
                  Track.buttonClick(button.become_creator, {
                    current_screen,
                  });
                  // setIsGetStartedModalOpened(true);
                }}
              >
                Become a creator
              </a>
            </li>
            <li>
              <Link
                href="/pricing"
                className="flex items-center h-auto"
                onClick={useCallback(() =>
                  Track.linkClick(link.pricing, {
                    url: "/pricing",
                    current_screen,
                  })
                )}
              >
                <span>Pricing</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-span-1 mt-10 sm:row-start-2 xl:row-start-1 xl:col-span-2 sm:mt-0">
          <span className="font-bold">Features</span>
          <ul
            className={cls(
              "mt-5 space-y-3",
              isLightMode
                ? "text-[#5A5A5A] hover:children:text-black"
                : "text-[#B8B8B8] hover:children:text-white"
            )}
          >
            {featuredPages.map((page) => {
              return (
                <li key={page.url}>
                  <Link
                    href={`${page.url}`}
                    target="_blank"
                    rel="noopener"
                    onClick={useCallback(() =>
                      Track.linkClick(page.trackLabel, {
                        url: page.url,
                        current_screen,
                      })
                    )}
                  >
                    {page.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-span-1 mt-10 sm:row-start-3 xl:row-start-1 md:row-start-2 xl:col-span-2 sm:mt-0">
          <span className="font-bold">Help & Guide</span>
          <ul
            className={cls(
              "mt-5 space-y-3",
              isLightMode
                ? "text-[#5A5A5A] hover:children:text-black"
                : "text-[#B8B8B8] hover:children:text-white"
            )}
          >
            <li>
              <Link
                href="/support"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.support, {
                    url: "/support",
                    current_screen,
                  })
                )}>
                  24x7 Support
              </Link>
            </li>
            <li>
              <Link
                href="/pages/creators-guide"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.creators_guide, {
                    url: "/creators-guide",
                    current_screen,
                  })
                )}
              >
                Creator Guide
              </Link>
            </li>
            <li>
              <Link
                href="/pages/community-guidelines"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.community_guidelines, {
                    url: "/community-guidelines",
                    current_screen,
                  })
                )}
              >
                Guidelines
              </Link>
            </li>
            <li>
              <Link
                href="/pages/faq"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.faq, {
                    url: "/faq",
                    current_screen,
                  })
                )}
              >
                FAQs
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-span-1 mt-10 sm:row-start-3 md:row-start-2 xl:row-start-1 sm:col-span-1 xl:col-span-2 sm:mt-0">
          <span className="font-bold">Follow us on</span>
          <ul
            className={cls(
              "flex mt-5 space-x-4 children:hover:scale-105",
              isLightMode ? "text-[#16191B]" : "text-[#B8B8B8]"
            )}
          >
            <li>
              <a
                href="https://www.facebook.com/qoohooapp"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.qoohoo_facebook, {
                    url: "https://www.facebook.com/qoohooapp",
                    current_screen,
                  })
                )}
              >
                <img
                  src={
                    isLightMode
                      ? "/assets/social-icons/fb-black.svg"
                      : "/assets/social-icons/fb.svg"
                  }
                  alt="Facebook Link"
                  className="w-6 h-6 select-none"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/qoohooapp"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.qoohoo_instagram, {
                    url: "https://www.instagram.com/qoohooapp",
                    current_screen,
                  })
                )}
              >
                <img
                  src={
                    isLightMode
                      ? "/assets/social-icons/ig-black.svg"
                      : "/assets/social-icons/ig.svg"
                  }
                  alt="Instagram Link"
                  className="w-6 h-6 select-none"
                />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/qoohooapp"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.qoohoo_twitter, {
                    url: "https://www.twitter.com/qoohooapp",
                    current_screen,
                  })
                )}
              >
                <img
                  src={
                    isLightMode
                      ? "/assets/social-icons/twitter-black.svg"
                      : "/assets/social-icons/twitter.svg"
                  }
                  alt="Twitter Link"
                  className="w-6 h-6 select-none"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/qoohoo"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.qoohoo_linkedin, {
                    url: "https://www.linkedin.com/company/qoohoo",
                    current_screen,
                  })
                )}
              >
                <img
                  src={
                    isLightMode
                      ? "/assets/social-icons/linkedin-black.svg"
                      : "/assets/social-icons/linkedin.svg"
                  }
                  alt="LinkedIn Link"
                  className="w-6 h-6 select-none"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCBQjtd5P-40fBg1nnq5uK1w"
                target="_blank"
                rel="noopener"
                onClick={useCallback(() =>
                  Track.linkClick(link.qoohoo_youtube, {
                    url: "https://www.youtube.com/channel/UCBQjtd5P-40fBg1nnq5uK1w",
                    current_screen,
                  })
                )}
              >
                <img
                  src={
                    isLightMode
                      ? "/assets/social-icons/yt-black.svg"
                      : "/assets/social-icons/yt.svg"
                  }
                  alt="Youtube Channel Link"
                  className="w-6 h-6 select-none"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr
        className={cls(
          "-px-5 sm:-mx-8 md:-mx-12 lg:-mx-20",
          isLightMode ? "bg-[#16191B]" : "opacity-[0.12]"
        )}
      />
      <div
        className={cls(
          "flex-col sm:flex-row sm:justify-between py-6 text-[13px] sm:text-base flex",
          isLightMode ? "text-[#4E4E4E]" : "text-[#929292]"
        )}
      >
        <ul
          className={cls(
            "flex space-x-8",
            isLightMode
              ? "hover:children:text-black"
              : "hover:children:text-white"
          )}
        >
          <li>
            <a
              href="/pages/privacy"
              target="_blank"
              rel="noopener"
              onClick={useCallback(() =>
                Track.linkClick(link.privacy_policy, {
                  url: "/privacy",
                  current_screen,
                })
              )}
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="/pages/stdeula"
              target="_blank"
              rel="noopener"
              onClick={useCallback(() =>
                Track.linkClick(link.terms_conditions, {
                  url: "/stdeula",
                  current_screen,
                })
              )}
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a
              href="/pages/refunds-and-cancellation"
              target="_blank"
              rel="noopener"
              onClick={useCallback(() =>
                Track.linkClick(link.refund_cancellation, {
                  url: "/refunds-and-cancellation",
                  current_screen,
                })
              )}
            >
              Refund Policy
            </a>
          </li>
        </ul>
        <span className="mt-4 sm:mt-0">
          © 2021 INDream Technologies Pvt Ltd.
        </span>
      </div>
    </footer>
  );
};

export default IndexFooter;
