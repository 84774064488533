import { useState } from "react";
import cls from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { InView } from "react-intersection-observer";

const InvestorCard = ({ id, name, url, about }) => {
  return (
    <div
      className={cls(
        "flex flex-col items-center hover:scale-105 investor-card transition-all transform-gpu ",
        {
          "sm:col-span-3 md:col-span-1": id === 9,
        }
      )}
      key={id}
    >
      <div className="h-16 w-16 sm:h-[88px] sm:w-[88px] rounded-full overflow-hidden object-cover object-center bg-[#22272B]">
        <img src={url} alt={name} className="select-none" />
      </div>
      <div className="mt-4 text-sm font-medium text-center sm:mt-5 sm:text-base">
        {name}
      </div>
      <div className="text-[13px] text-[#B4BCC3] mt-1.5 text-center whitespace-pre-wrap">
        {about}
      </div>
    </div>
  );
};

const InvestorSection = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animateHeader, setAnimateHeader] = useState(false);

  const investors = [
    {
      name: "Hugo Amsellem",
      url: "/assets/images/investor-hugo.webp",
      about: "Investor, Creator Economy",
    },
    {
      name: "Kunal Shah",
      url: "/assets/images/investor-kunal.webp",
      about: "Founder, CRED",
    },
    {
      name: "Kunal Bahl",
      url: "/assets/images/investor-bahl.webp",
      about: "Co-Founder, Snapdeal",
    },
    {
      name: "Rohit Bansal",
      url: "/assets/images/investor-rohit.webp",
      about: "Co-Founder, Snapdeal",
    },
    {
      name: "Gaurav Munjal",
      url: "/assets/images/investor-gaurav.webp",
      about: "Founder, Unacademy",
    },
    {
      name: "Yash Jain",
      url: "/assets/images/investor-yash.webp",
      about: "Founder, Sparrow Capital",
    },
    {
      name: "Sujeet Kumar",
      url: "/assets/images/investor-sujeet.webp",
      about: "Co-Founder, Udaan",
    },
    {
      name: "Pankaj Gupta",
      url: "/assets/images/investor-pankaj.webp",
      about: "Coinbase, Ex\u2011Twitter",
    },
    {
      name: "Utsav Somani",
      url: "/assets/images/investor-utsav.webp",
      about: "India Partner, Angel List",
    },
    {
      name: "Revant Bhate",
      url: "/assets/images/investor-revant.webp",
      about: "Founder, Man Matters",
    },
  ];

  return (
    <div className="px-5 pb-20 sm:py-20 sm:px-8 md:px-12 lg:px-[4.25rem] xl:px-20">
      <InView
        onChange={(inView) => {
          if (inView) {
            setAnimateHeader(true);
          }
        }}
        rootMargin="-60% 0% -40% 0%"
        className={cls(
          "transition-all header-reveal-animation",
          { "translate-y-8 opacity-0": !animateHeader },
          {
            "translate-y-0 opacity-100 block": animateHeader,
          }
        )}
      >
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center font-inter mx-auto !leading-[1.4] mb-14 sm:mb-16 md:mb-[72px]">
          Investors who believe in us 🚀
        </h2>
        <YCLogo
          className={
            "mx-auto mb-12 sm:mb-14 md:mb-16 w-full"
          }
        />
      </InView>
      <div className="mx-auto hidden sm:grid grid-cols-3 grid-rows-4 md:grid-cols-5 md:grid-rows-2 grid-flow-row max-w-[860px] gap-y-14 gap-x-2">
        {investors.map((investor, id) => {
          return (
            <InvestorCard
              key={id}
              id={id}
              name={investor.name}
              about={investor.about}
              url={investor.url}
            />
          );
        })}
      </div>
      <div className="w-full overflow-hidden sm:hidden ">
        <Swiper
          onSwiper={setSwiperRef}
          className="w-full transition-transform"
          onSlideChange={(swiper) => {
            setCurrentSlide(swiper.activeIndex);
          }}
          slideToClickedSlide={true}
        >
          <SwiperSlide style={{ cursor: "grab" }}>
            <div className="grid grid-cols-2 grid-rows-3 gap-x-2 gap-y-8 py-2 sm:py-0">
              {investors.slice(0, 6).map((investor, id) => {
                return (
                  <InvestorCard
                    key={id}
                    id={id}
                    name={investor.name}
                    about={investor.about}
                    url={investor.url}
                  />
                );
              })}
            </div>
          </SwiperSlide>
          <SwiperSlide style={{ cursor: "grab" }}>
            <div className="grid grid-cols-2 grid-rows-3 gap-x-2 gap-y-12 py-2 sm:py-0">
              {investors.slice(6, 10).map((investor, id) => {
                return (
                  <InvestorCard
                    key={id}
                    id={id}
                    name={investor.name}
                    about={investor.about}
                    url={investor.url}
                  />
                );
              })}
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="flex items-center justify-center mt-8 transition-all">
          {currentSlide === 1 && (
            <button
              onClick={() => {
                swiperRef.slidePrev();
              }}
              className="h-10 w-10 rounded-full bg-[#22272B] flex items-center justify-center"
            >
              <img
                src="/assets/icons/chevron-down.svg"
                alt="next investors icon"
                className="rotate-90 select-none"
              />
            </button>
          )}
          {currentSlide === 0 && (
            <button
              onClick={() => {
                swiperRef.slideNext();
              }}
              className="h-10 w-10 rounded-full bg-[#22272B] flex items-center justify-center"
            >
              <img
                src="/assets/icons/chevron-down.svg"
                alt="next investors icon"
                className="-rotate-90 select-none"
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

function YCLogo({ className }) {
  return (
    <svg
      className={className}
      width="232"
      height="48"
      viewBox="0 0 232 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2405_4930)">
        <path d="M48 0H0V48H48V0Z" fill="#F26625" />
        <path
          d="M22.3981 27.1997L14.0781 11.6797H17.9181L22.7181 21.4397C22.7181 21.5997 22.8781 21.7597 23.0381 21.9197C23.1981 22.0797 23.1981 22.2397 23.3581 22.5597L23.5181 22.7197V22.8797C23.6781 23.1997 23.6781 23.3597 23.8381 23.6797C23.9981 23.8397 23.9981 24.1597 24.1581 24.3197C24.3181 23.8397 24.6381 23.5197 24.7981 22.8797C24.9581 22.3997 25.2781 21.9197 25.5981 21.4397L30.3981 11.6797H33.9181L25.5981 27.3597V37.2797H22.3981V27.1997Z"
          fill="white"
        />
        <path
          d="M69.92 12.2803C72.32 12.2803 74.4 12.9203 76 14.2003L74.4 16.1203C72.96 15.1603 71.52 14.5203 69.76 14.5203C67.04 14.5203 64.96 15.9603 63.84 18.6803C63.2 20.2803 62.88 22.3603 62.88 25.0803C62.88 27.1603 63.2 28.9203 63.68 30.2003C64.96 33.2403 67.04 34.6803 70.24 34.6803C72 34.6803 73.6 34.2003 75.04 33.0803L76.64 35.1603C74.56 36.4403 72.32 37.0803 69.92 37.0803C67.04 37.0803 64.64 35.9603 62.72 33.5603C60.8 31.3203 60 28.2803 60 24.6003C60 20.9203 60.96 18.0403 62.88 15.6403C64.64 13.5603 67.04 12.2803 69.92 12.2803Z"
          fill="#F26625"
        />
        <path
          d="M79.0391 28.1198C79.0391 25.2398 79.6791 22.9998 81.1191 21.3998C82.5591 19.7998 84.3191 18.8398 86.5591 18.8398C89.1191 18.8398 91.1991 19.7998 92.6391 21.8798C93.7591 23.4798 94.2391 25.7198 94.2391 28.4398C94.2391 31.6398 93.2791 34.1998 91.5191 35.7998C90.2391 36.9198 88.6391 37.5598 86.7191 37.5598C84.3191 37.5598 82.5591 36.7598 81.1191 34.9998C79.6791 33.3998 79.0391 30.9998 79.0391 28.1198ZM90.2391 23.4798C89.4391 22.0398 88.3191 21.2398 86.5591 21.2398C84.7991 21.2398 83.6791 21.8798 82.8791 23.1598C82.2391 24.1198 82.0791 25.7198 82.0791 27.7998C82.0791 30.5198 82.3991 32.4398 83.1991 33.5598C83.9991 34.6798 85.1191 35.3198 86.7191 35.3198C88.6391 35.3198 89.9191 34.3598 90.5591 32.5998C90.8791 31.6398 91.0391 30.3598 91.0391 28.7598C91.1991 26.3598 90.8791 24.5998 90.2391 23.4798Z"
          fill="#F26625"
        />
        <path
          d="M99.3587 23.7998C99.3587 22.0398 99.1988 20.5998 98.7188 19.4798L101.439 18.8398C101.919 19.6398 102.079 20.5998 102.079 21.3998V21.5598C102.719 20.9198 103.359 20.2798 104.319 19.7998C105.439 19.1598 106.399 18.8398 107.359 18.8398C108.799 18.8398 110.079 19.4798 110.879 20.5998C111.039 20.9198 111.359 21.3998 111.519 21.7198C113.439 19.7998 115.199 18.8398 117.119 18.8398C118.399 18.8398 119.519 19.3198 120.319 20.1198C121.119 21.0798 121.599 22.1998 121.599 23.4798V36.7598H118.719V23.6398C118.719 21.8798 117.919 21.0798 116.479 21.0798C115.679 21.0798 114.719 21.3998 113.919 22.0398C113.599 22.3598 112.959 22.8398 112.159 23.4798L111.839 23.7998V36.7598H108.799V24.2798C108.799 23.1598 108.639 22.3598 108.319 22.0398C107.839 21.5598 107.359 21.3998 106.559 21.3998C105.279 21.3998 103.839 22.1998 102.079 23.7998V36.9198H99.3587V23.7998Z"
          fill="#F26625"
        />
        <path
          d="M126.559 11.64L129.439 11C129.759 12.28 129.919 13.72 129.919 15.48V19.48C129.919 20.44 129.919 21.08 129.919 21.4C131.519 19.8 133.279 19 135.039 19C137.119 19 138.879 19.8 139.999 21.4C141.279 23 141.919 25.24 141.919 27.96C141.919 30.84 141.279 33.08 139.999 34.84C138.719 36.6 136.959 37.4 134.879 37.4C133.919 37.4 133.119 37.24 132.159 36.76C131.199 36.28 130.559 35.8 130.079 35.16C129.919 35.96 129.759 36.6 129.599 37.08H126.879C127.199 36.28 127.359 34.84 127.359 32.6V15.8C127.199 13.88 127.039 12.44 126.559 11.64ZM131.199 22.52C130.719 22.84 130.239 23.32 129.919 23.8V32.92C131.039 34.36 132.479 35 134.239 35C135.679 35 136.799 34.52 137.439 33.4C138.239 32.12 138.719 30.36 138.719 27.8C138.719 25.56 138.399 23.96 137.599 23C136.959 22.04 135.839 21.56 134.239 21.56C133.279 21.4 132.159 21.72 131.199 22.52Z"
          fill="#F26625"
        />
        <path
          d="M146.078 13.8796C146.078 13.2396 146.238 12.7596 146.718 12.2796C147.198 11.7996 147.678 11.6396 148.318 11.6396C148.958 11.6396 149.438 11.7996 149.918 12.2796C150.398 12.7596 150.558 13.2396 150.558 13.8796C150.558 14.5196 150.398 14.9996 149.918 15.4796C149.438 15.9596 148.958 16.1196 148.318 16.1196C147.678 16.1196 147.198 15.9596 146.718 15.4796C146.238 14.9996 146.078 14.5196 146.078 13.8796ZM146.718 36.9196V19.3196L149.598 18.8396V36.9196H146.718Z"
          fill="#F26625"
        />
        <path
          d="M155.839 23.7998C155.839 22.5198 155.839 21.7198 155.679 21.3998C155.679 20.9198 155.359 20.4398 155.039 19.6398L157.759 18.8398C158.239 19.7998 158.399 20.5998 158.399 21.5598C160.159 19.7998 162.079 18.8398 163.999 18.8398C164.959 18.8398 165.759 18.9998 166.559 19.4798C167.359 19.9598 167.999 20.5998 168.319 21.3998C168.639 22.0398 168.799 22.6798 168.799 23.4798V36.9198H166.079V24.9198C166.079 23.4798 165.919 22.5198 165.439 22.0398C164.959 21.5598 164.319 21.2398 163.519 21.2398C162.879 21.2398 161.919 21.5598 160.959 22.0398C159.999 22.5198 159.199 23.1598 158.559 23.7998V36.9198H155.839V23.7998Z"
          fill="#F26625"
        />
        <path
          d="M175.201 23.1598L173.761 21.2398C176.161 19.6398 178.561 18.8398 181.121 18.8398C183.681 18.8398 185.281 19.7998 186.081 21.5598C186.401 22.1998 186.401 23.1598 186.401 24.5998V25.5598L186.241 31.3198C186.241 31.4798 186.241 31.7998 186.241 32.1198C186.241 33.0798 186.241 33.7198 186.401 34.1998C186.561 34.8398 187.041 35.3198 187.681 35.6398L186.241 37.5598C184.961 37.0798 184.161 36.2798 183.841 34.9998C182.241 36.5998 180.481 37.3998 178.721 37.3998C176.961 37.3998 175.521 36.9198 174.401 35.9598C173.441 35.1598 172.961 33.8798 172.961 32.2798C172.961 30.1998 173.761 28.7598 175.361 27.6398C176.961 26.5198 179.361 26.0398 182.241 26.0398C182.721 26.0398 183.041 26.0398 183.521 26.0398V24.7598C183.521 23.3198 183.361 22.3598 182.881 22.0398C182.241 21.3998 181.601 21.0798 180.481 21.0798C179.521 21.0798 178.401 21.3998 177.121 21.8798C176.641 22.1998 176.001 22.6798 175.201 23.1598ZM183.681 32.5998L183.841 27.9598C183.041 27.9598 182.561 27.9598 182.401 27.9598C179.841 27.9598 178.241 28.4398 177.281 29.3998C176.641 30.0398 176.321 30.9998 176.321 32.2798C176.321 34.3598 177.281 35.4798 179.361 35.4798C181.281 35.3198 182.721 34.3598 183.681 32.5998Z"
          fill="#F26625"
        />
        <path
          d="M195.518 19.3202H199.998L199.198 21.5602H195.518V32.9202C195.518 33.8802 195.678 34.5202 195.998 35.0002C196.318 35.3202 196.958 35.6402 197.758 35.6402C198.398 35.6402 199.038 35.4802 199.518 35.3202L199.838 37.0802C198.878 37.5602 197.918 37.7202 196.798 37.7202C194.078 37.7202 192.798 36.4402 192.798 33.7202V21.5602H190.398V19.3202H192.638V19.0002C192.638 18.6802 192.798 17.4002 192.958 15.3202V14.8402L195.838 14.2002C195.518 16.1202 195.518 17.8802 195.518 19.3202Z"
          fill="#F26625"
        />
        <path
          d="M202.879 28.1198C202.879 25.2398 203.519 22.9998 204.959 21.3998C206.239 19.7998 208.159 18.8398 210.399 18.8398C212.959 18.8398 215.039 19.7998 216.479 21.8798C217.599 23.4798 218.079 25.7198 218.079 28.4398C218.079 31.6398 217.119 34.1998 215.359 35.7998C214.079 36.9198 212.479 37.5598 210.559 37.5598C208.159 37.5598 206.399 36.7598 204.959 34.9998C203.679 33.3998 202.879 30.9998 202.879 28.1198ZM214.239 23.4798C213.439 22.0398 212.319 21.2398 210.559 21.2398C208.799 21.2398 207.679 21.8798 206.879 23.1598C206.239 24.1198 206.079 25.7198 206.079 27.7998C206.079 30.5198 206.399 32.4398 207.199 33.5598C207.999 34.6798 209.119 35.3198 210.719 35.3198C212.639 35.3198 213.919 34.3598 214.559 32.5998C214.879 31.6398 215.039 30.3598 215.039 28.7598C215.039 26.3598 214.719 24.5998 214.239 23.4798Z"
          fill="#F26625"
        />
        <path
          d="M223.359 23.4798C223.359 21.8798 223.199 20.5998 222.719 19.6398L225.439 18.8398C225.919 19.7998 226.079 20.5998 226.079 21.5598V21.8798C227.519 19.9598 229.119 18.9998 231.039 18.9998C231.359 18.9998 231.679 18.9998 231.999 19.1598L230.879 22.1998C230.559 22.0398 230.239 22.0398 230.079 22.0398C229.439 22.0398 228.639 22.1998 227.999 22.6798C227.359 23.1598 226.719 23.6398 226.399 24.2798C226.239 24.7598 226.079 25.3998 226.079 26.1998V37.2398H223.359V23.4798Z"
          fill="#F26625"
        />
      </g>
      <defs>
        <clipPath id="clip0_2405_4930">
          <rect width="232" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InvestorSection;
