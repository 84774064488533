import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-youtube";
import WaterMark from "./Watermark";
import {useWatermarkMutationObserver} from "./VideoPlayer";

const HidePlayer = () => {
  return (
    <div className="h-full border border-gray-500 bg-black text-white rounded-md flex justify-center items-center">
      <h1 className="text-lg p-3"> Something went wrong, please refresh the page.</h1>
    </div>
  );
};

export default function YoutubePlayer(props) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const watermarkRef = useRef(null);
  const [displayVideo, setDisplayVideo] = useState(true);
  const { options, onReady, showWatermark = false, trackVideoClick } = props;
  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    sources: props.sources,
    ...options,
  };

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        videoJsOptions,
        () => {
          console.log("player is ready");
          onReady && onReady(player);
        }
      ));

      // Dynamically set objectFit for portrait and landscape videos
      player.on("loadeddata", function () {
        if (videoElement?.videoWidth > videoElement?.videoHeight) {
          videoElement.style.objectFit = "cover";
        }
      });

      player.on("click", function () {
        if (typeof trackVideoClick === "function") {
          trackVideoClick();
        }
      });

      player.on("touchstart", function (e) {
        const playerRefCurrent = playerRef.current;

        if (e.target.nodeName === "VIDEO" && playerRefCurrent) {
          if (playerRefCurrent.paused()) {
            playerRefCurrent.play();
          } else {
            playerRefCurrent.pause();
          }
        }

        if (typeof trackVideoClick === "function") {
          trackVideoClick();
        }
      });
    } else {
      // you can update player here [update player through props]
      // const video = videoRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const playerRefCurrent = playerRef.current;

    if (playerRefCurrent && !playerRefCurrent.paused()) {
      playerRefCurrent.pause();
    }
  }, [props.currentSlide]);


  function onWaterMarkTouched() {
    setDisplayVideo(false);
  }

  useWatermarkMutationObserver(containerRef, [watermarkRef], onWaterMarkTouched);

  return (
    displayVideo ? (
      <div className={"relative h-full"} data-vjs-player ref={containerRef}>
        {
          showWatermark && <WaterMark text={"qoohoo"} ref={watermarkRef}/>
        }
        <video
          ref={videoRef}
          className="video-js vjs-big-play-centered w-full h-full youtube-player"
        />
      </div>
    ):<HidePlayer/>

  );
}
