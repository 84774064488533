import { useCallback, useState } from "react";
import { Disclosure } from "@headlessui/react";
import cls from "classnames";
import { InView } from "react-intersection-observer";

import * as Track from "@qoohoo/common/utils/analytics/track";
import { button, pageview } from "@qoohoo/common/constants/analytics";
import useStore from "../../store/store";
import FAQ from "../FAQ";
import {creatorOnboardingUrl} from "../../constants/constants";

const FAQSection = () => {
  const [animateHeader, setAnimateHeader] = useState(false);
  const setIsGetStartedModalOpened = useStore(
    (state) => state.setIsGetStartedModalOpened
  );

  const faqs = [
    {
      q: "How do I become a creator on qoohoo?",
      a: (
        <>
          It's simple. Creators can launch their communities in less than 60
          seconds.{" "}
          <div className="inline">
            <a
              href={creatorOnboardingUrl}
              target="_blank"
              rel="noopener"
              className={cls(
                "cursor-pointer",
              )}
              onClick={() => {
                Track.buttonClick(button.become_creator, {
                current_screen: pageview.home,
                element: "faq",
              });
                // setIsGetStartedModalOpened(true);
              }}
            >
              <span className="font-bold underline cursor-pointer">
                Become a creator
              </span>
            </a>
          </div>{" "}
          and fill the creator form to unlock creator's universe.
        </>
      ),
    },
    {
      q: "What category of creators can join qoohoo?",
      a: "Any creator or professional who have their existing communities on Telegram, Whatsapp, Slack or Discord can join qoohoo to automate the monetisation, management and get actionable analytics for their communities.",
    },
    {
      q: "How do members join my community?",
      a: "Members join your communities automatically after the payment is done. They get the reminders on Whatsapp & SMS if their subscriptions are getting over. There is no need to download the qoohoo app for users.",
    },
    {
      q: "In what ways can I engage with my members?",
      a: "Some trending features like Slot Booking, Courses, Discount Coupons, Premium Locked Content help creators to launch their content in various ways for their communities to engage and monetise easily.",
    },
    {
      q: "Can I accept international payments from members?",
      a: "Yes, members from anywhere across the globe can join your channels. qoohoo allows you to collect international payments easily.",
    },
  ];

  const trackingDetails = {
    current_screen: pageview.home,
  };

  return (
    <section className="px-5 pt-0 pb-24 md:pt-20 md:pb-40 sm:px-20 lg:px-[4.25rem] xl:px-20 font-nunito max-w-[1536px] mx-auto">
      <InView
        onChange={(inView) => {
          if (inView) {
            setAnimateHeader(true);
          }
        }}
        rootMargin="-60% 0% -40% 0%"
        className={cls(
          "transition-all header-reveal-animation",
          { "translate-y-8 opacity-0": !animateHeader },
          {
            "translate-y-0 opacity-100 block": animateHeader,
          }
        )}
      >
        <h2 className="w-full text-2xl font-bold text-center sm:text-4xl md:text-5xl font-inter">
          FAQs
        </h2>
      </InView>
      <div className="text-[#DBDBDB] mt-14 space-y-6 w-full md:flex md:flex-col items-center justify-center max-w-[1120px] mx-auto">
        <FAQ faqs={faqs} {...trackingDetails} />
      </div>
    </section>
  );
};

export default FAQSection;
