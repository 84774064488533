import cls from "classnames";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";

const CarouselDesktop = ({
  onToolChange,
  selectedIndex,
  Tools,
  timeToNextIndex,
}) => {
  return (
    <div className="col-span-full w-full mt-16 hidden bg-[#1D2225] bg-opacity-40 rounded-[60px] relative text-white font-nunito overflow-hidden lg:grid grid-cols-12  grid-flow-col gap-5 py-[3.75rem]">
      <div className="lg:col-start-1 xl:col-start-2 col-end-7">
        <Transition
          key={selectedIndex}
          appear={true}
          show={true}
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="transform opacity-0 scale-[0.98]"
          enterTo="transform opacity-100 scale-[1]"
        >
          <div className="aspect-w-1 aspect-h-1 h-[540px]">
            <img
              src={Tools[selectedIndex].image}
              className="object-contain h-full w-full"
            />
          </div>
        </Transition>
      </div>
      <div className="col-start-7 col-end-12 flex flex-col justify-center">
        <div className="relative w-[max-content] min-w-[360px]">
          <div
            className="w-full h-14 rounded-xl absolute bg-[#22272B] z-[-1] top-0 transition-all"
            style={{
              top: `${selectedIndex * 56}px`,
              transitionDuration: `${timeToNextIndex.className}s`,
              transitionTimingFunction: "ease-in-out",
            }}
          ></div>
          {Tools.map((Tool, index) => {
            const isSelected = index === selectedIndex;

            return (
              <div
                key={index}
                className="py-2 px-4 w-full flex items-center cursor-pointer tool-selected whitespace-nowrap"
                onClick={() => onToolChange(index)}
              >
                <Tool.icon
                  className="object-contain h-10 w-10 mr-5"
                  style={{
                    color: isSelected ? Tool.highlightColor[0] : "white",
                  }}
                  fillPrimary={isSelected && Tool.highlightColor[0]}
                  fillSecondary={isSelected && Tool.highlightColor?.[1]}
                />
                <span
                  className={cls(
                    "font-nunito leading-[1.625rem]",
                    isSelected ? "font-bold text-[1.375rem]" : "text-xl"
                  )}
                >
                  {Tool.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CarouselDesktop;
