import React, { useEffect, useState } from "react";
import cls from "classnames";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-youtube";
import WaterMark, {WaterMarkPositions} from "./Watermark";
import useSessionStore from "../store/sessionStore";
import useHasHydrated from "@qoohoo/common/hooks/useHasHydrated";

export default function VideoPlayer(props) {
  const [isPaused, setIsPaused] = useState(false);
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const containerRef = React.useRef(null);
  const watermarkRef1 = React.useRef(null);
  const watermarkRef2 = React.useRef(null);
  const [displayVideo, setDisplayVideo] = React.useState(true);
  const [name, setName] = React.useState("initial name");
  const [phone, setPhone] = React.useState("phone");
  const [hasUserData, setHasUserData] = React.useState(false);

  const {
    options,
    onReady,
    videoPlayerClassName,
    trackVideoClick,
    showOverlay = true,
    showWatermark = false,
    channelName,
    creatorName,
  } = props;
  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    sources: props.sources,
    ...options,
  };

  const userData = useSessionStore((state) => state.userData);

  const isBrowser = useHasHydrated();

  React.useEffect(() => {
    var tempName = userData["name"] ?? userData["username"];
    var tempPhone = "+" + userData["countryCode"] + userData["mobile"];
    var tempHasUserData = !!userData?.uid;

    setHasUserData(tempHasUserData);
    if (tempName) {
      setName(tempName);
    }
    if (tempPhone) {
      setPhone(tempPhone);
    }
  }, [userData]);

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        videoJsOptions,
        () => {
          console.log("player is ready");

          playerRef.current?.currentTime?.(5); // seek to 5s for thumbnail

          onReady && onReady(player);
        }
      ));

      // Dynamically set objectFit for portrait and landscape videos
      player.on("loadeddata", function () {
        if (videoElement?.videoWidth > videoElement?.videoHeight) {
          videoElement.style.objectFit = "cover";
        }
      });

      player.one("play", function () {
        // We seeked to 5s for thumbnail generation, reset to 0s once playback is requested.
        playerRef.current?.currentTime?.(0);
      });

      player.on("click", function () {
        if (typeof trackVideoClick === "function") {
          trackVideoClick();
        }
      });

      player.on('pause', function () {
        setIsPaused(true);
      });

      player.on('play', function () {
        setIsPaused(false);
      });

      player.on("touchstart", function (e) {
        const playerRefCurrent = playerRef.current;

        if (e.target.nodeName === "VIDEO" && playerRefCurrent) {
          if (playerRefCurrent.paused()) {
            playerRefCurrent.play();
          } else {
            playerRefCurrent.pause();
          }
        }

        if (typeof trackVideoClick === "function") {
          trackVideoClick();
        }
      });
    } else {
      // you can update player here [update player through props]
      // const video = videoRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  React.useEffect(() => {
    const playerRefCurrent = playerRef.current;

    if (playerRefCurrent && !playerRefCurrent.paused()) {
      playerRefCurrent.pause();
    }
  }, [props.currentSlide]);

  function onWaterMarkTouched() {
    setDisplayVideo(false);
  }

  useWatermarkMutationObserver(containerRef, [watermarkRef1, watermarkRef2], onWaterMarkTouched);

  const pausePlayer = () => {
    const playerRefCurrent = playerRef.current;

    if (playerRefCurrent) {
      if (playerRefCurrent.paused()) {
        playerRefCurrent.play();
      } else {
        playerRefCurrent.pause();
      }
    }
  };

  function renderWatermark() {
    let watermarkComp = null;

    if (isBrowser) {
      if (hasUserData) {
        watermarkComp = (
          <WaterMark
            text={`${creatorName}\nuser:${name} / ${phone}`}
            ref={watermarkRef1}
            align={WaterMarkPositions.center}
            isAnimated
          />
        );
      } else {
        watermarkComp = null;
      }
    }

    return watermarkComp;
  }

  return (
    displayVideo && (
      <div className={"relative h-full"} data-vjs-player ref={containerRef}>
        {showWatermark && (
          <WaterMark
            ref={watermarkRef2}
            text={channelName}
            align={WaterMarkPositions.bottomLeft}
          />
        )}
        {showWatermark && renderWatermark()} {/* User details watermark */}
        <video
          ref={videoRef}
          className={cls(
            "video-js vjs-big-play-centered w-full h-full",
            videoPlayerClassName
          )}
        />
        {showOverlay && (
          <div
            onClick={pausePlayer}
            className={cls(
              "absolute h-full w-full inset-0 transition-colors duration-300",
              isPaused
                ? "bg-gradient-to-b from-transparent via-black/80 to-black"
                : "bg-transparent"
            )}
          ></div>
        )}
      </div>
    )
  );
}

export const useWatermarkMutationObserver = (containerRef, childrenRef=[], cb) => {
  React.useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // If the child watermark is removed, then call the callback
        if (childrenRef.indexOf(mutation.removedNodes[0]) > -1) {
          observer.disconnect();
          cb && cb();
          // If the parent container style is modified, then call the callback
        } else if (
          childrenRef.find(
            (ref) => ref.current?.nodeName === mutation.target.nodeName
          )
        ) {
          observer.disconnect();
          cb && cb();
          // If the child text is changed i.e. watermark text changed, then call the callback
        } else if (mutation.type === "characterData") {
          observer.disconnect();
          cb && cb();
          // If the child text is removed i.e. watermark is deleted, then call the callback
        } else if (
          mutation.type === "childList" && // childList is modified
          mutation.addedNodes.length === 0 && // no new node is added (so page is not building)
          childrenRef.find(
            (ref) =>
              ref.current?.nodeName === mutation.removedNodes[0]?.nodeName // removed node is a watermark
          )
        ) {
          observer.disconnect();
          cb && cb();
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true,
      });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  });
}
