import cls from "classnames";

export default function PageBanner({children, className}) {
  return (
    <div className={cls(
      "flex flex-wrap px-4 py-2.5 justify-center items-center",
      className
    )}>
      {children}
    </div>
  );
}