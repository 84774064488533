import React from 'react';
import YoutubePlayer from "../../components/YoutubePlayer";

const ExperienceSection = () => {
  const src = "https://www.youtube.com/watch?v=itL39HSCR8s"

  return (
    <section className="flex flex-col gap-14 pt-20 lg:relative lg:grid lg:grid-flow-col lg:grid-cols-12 sm:py-20 lg:gap-5 px-5 sm:px-8 md:px-12 lg:px-[4.25rem] xl:px-20 font-nunito items-center max-w-[1536px] 2xl:mx-auto">
        <div className='max-w-none sm:max-w-[80%] lg:max-w-[464px] lg:col-start-1 xl:col-start-2 lg:col-end-7 self-center lg:mr-3 lg:self-auto xl:mr-0'>
            <div className='font-nunito text-center text-2xl leading-8 lg:text-left sm:text-5xl sm:leading-[72px] text-white font-extrabold mb-6'>Experience qoohoo ecosystem</div>
            <div className='font-nunito text-center text-sm lg:text-left sm:text-xl sm:leading-9 text-white font-normal'>Understand how qoohoo app empowers creator to launch different programs at one place in a secure and scalable way 😎</div>
        </div>
        <div className='col-start-7 col-end-13 md:max-w-[620px] lg:max-w-none xl:max-w-[572px] w-full'>
          <div className='w-full aspect-w-16 aspect-h-9'>
            <div className='w-full h-full'>
              <YoutubePlayer
              sources={[{ src, type: "video/youtube" }]}
              showOverlay={false}
              />
            </div>
          </div>
        </div>
    </section>
  )
}

export default ExperienceSection