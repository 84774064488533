import { Disclosure } from "@headlessui/react";
import cls from "classnames";

import * as Track from "@qoohoo/common/utils/analytics/track";
import { button } from "@qoohoo/common/constants/analytics";
import { ChevronDownIcon } from "@qoohoo/common/library/Icons";

const FAQ = ({
  faqs,
  className,
  style,
  current_screen,
  is_telegram = false,
  defaultOpen = false,
  fullWidth = false,
  isWhiteTheme,
  isLightMode = false,
  faqQuestionBackground,
  faqAnswerBackground,
  userData,
  pageUrl,
  showBorder,
}) => {
  return (
    <>
      {faqs.map((faq, id) => {
        return (
          <Disclosure
            as="div"
            key={id}
            className={cls(
              "rounded-lg overflow-hidden transition-all duration-1000 border-t-0 border-b-0",
              fullWidth ? "md:w-full" : "md:w-3/4 lg:w-3/4",
              isLightMode
                ? showBorder
                  ? "border border-[#16191B] border-t-0 border-b-0"
                  : ""
                : showBorder
                ? `bg-[#22272B] dark:bg-transparent dark:border border-[#D5D8DC] ${
                    isWhiteTheme ? "" : "dark:border-[#16191B]"
                  }`
                : `bg-[#22272B] dark:bg-transparent`,
              className
            )}
            style={{
              backgroundColor: isLightMode ? faqAnswerBackground : null,
              ...style,
            }}
            defaultOpen={defaultOpen}
            onClick={() => {
              Track.buttonClick(button.faq, {
                current_screen,
                element: "faq",
                is_telegram,
                faq_id: id,
                faq_question: faq.q,
              });
            }}
          >
            {({ open }) => {
              let faqAnswer;

              switch(typeof faq.a) {
                case "string": 
                  faqAnswer = <p dangerouslySetInnerHTML={{__html: faq.a}} />
                  break;
                case "function": 
                  faqAnswer = faq.a({ userData, pageUrl })
                  break;
                default: faqAnswer = faq.a;
              }

              return (
              <>
                <Disclosure.Button
                  className={cls(
                    "flex justify-between items-center w-full py-[14px] sm:py-5 px-4 sm:px-8 outline-none",
                    isLightMode
                      ? showBorder
                        ? "text-[#16191B] border border-[#16191B] border-l-0 border-r-0 rounded-lg"
                        : "text-[#16191B] rounded-lg"
                      : showBorder
                      ? "bg-[#1D2225] border-[#D5D8DC] dark:bg-white dark:border-[#D5D8DC] dark:border-b"
                      : "bg-[#1D2225] dark:bg-white"
                  )}
                  style={{
                    backgroundColor: isLightMode ? faqQuestionBackground : null,
                  }}
                >
                  <span className="text-sm font-semibold text-left md:text-base lg:text-lg dark:text-[#16191B]">
                    {faq.q}
                  </span>
                  <ChevronDownIcon
                    className={cls(
                      "transition-all ml-4 select-none stroke-current shrink-0",
                      open ? "rotate-180" : "",
                      isLightMode
                        ? "text-[#16191B]"
                        : "text-[#fff] dark:text-[#16191B]"
                    )}
                  />
                </Disclosure.Button>
                <Disclosure.Panel
                  className={cls(
                    "px-4 py-5 sm:px-8 sm:py-6 text-sm md:text-base translate",
                    isLightMode
                      ? showBorder
                        ? "text-[#16191B] border-b border-[#16191B] rounded-lg"
                        : "text-[#16191B]"
                      : "dark:text-[#16191B] dark:bg-[#ECECEC]"
                  )}
                >
                  {faqAnswer}
                </Disclosure.Panel>
              </>
            )}}
          </Disclosure>
        );
      })}
    </>
  );
};

export default FAQ;
